import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import "src/fonts/cera-pro/stylesheet.css";

// Create a theme instance.
const _BaseTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#00aeef",
    },
    secondary: {
      main: "#797B7A",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
    grey: {
      default: "#797B7A",
    },
    orange: {
      default: "#DE9145",
    },
    indigo: {
      default: "#45518D",
    },
    purple: {
      default: "#475AA2",
    },
    dark_blue: {
      default: "#101332",
    },
  },
  typography: {
    fontFamily: [
      "Cera Pro",
      "Montserrat",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(", "),
    h1: {
      fontWeight: "revert",
    },
    h2: {
      fontWeight: "revert",
    },
    h3: {
      fontWeight: "revert",
    },
    h4: {
      fontWeight: "revert",
    },
    // h5: {
    //   fontWeight: 'revert'
    // },
    h6: {
      fontWeight: "revert",
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        borderRadius: "70px",
        color: "white",
        fontSize: "1em",
        fontWeight: "bold",
        padding: "1em 2em",
        boxShadow: "0px 0px 1em rgba(13, 13, 13, 0.25)",
      },
      outlinedPrimary: {
        borderRadius: "70px",
        fontSize: "1em",
        fontWeight: "bold",
        padding: "0.95em 1.95em",
      },
      root: {
        boxShadow: "none",
        fontSize: "1.2em",
        fontWeight: 400,
        lineHeight: "1em",
        textTransform: "none",
      },
    },
  },
});

const BaseTheme = responsiveFontSizes(_BaseTheme);
export { BaseTheme };
export default BaseTheme;
