import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_SITE_KEY } from "src/config";
import { Helmet } from "react-helmet";
import "./layout.css";
import { useTheme } from "@material-ui/core";

const queryClient = new QueryClient();

const title = "Fieldscope - Let's Do Good Better.";
const description =
  "Reimagine social-sector operations through simple and smart technology. Our curated pool of talent can fulfill your on-demand project needs and further your social mission.";

export default ({ children }) => {
  const theme = useTheme();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
      <QueryClientProvider client={queryClient}>
        <Helmet
          title={title}
          defer={false}
          htmlAttributes={{
            lang: "en",
          }}
          meta={[
            {
              name: `description`,
              content: description,
            },
            {
              property: `og:title`,
              content: title,
            },
            {
              property: `theme-color`,
              content: theme.palette.primary.main,
            },
          ]}
        />
        {/* <html lang="en" />
      </Helmet> */}
        {children}
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
};
