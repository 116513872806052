// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contract-tsx": () => import("./../../../src/pages/contract.tsx" /* webpackChunkName: "component---src-pages-contract-tsx" */),
  "component---src-pages-document-tsx": () => import("./../../../src/pages/document.tsx" /* webpackChunkName: "component---src-pages-document-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invoice-tsx": () => import("./../../../src/pages/invoice.tsx" /* webpackChunkName: "component---src-pages-invoice-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-p-tsx": () => import("./../../../src/pages/p.tsx" /* webpackChunkName: "component---src-pages-p-tsx" */),
  "component---src-pages-project-tsx": () => import("./../../../src/pages/project.tsx" /* webpackChunkName: "component---src-pages-project-tsx" */),
  "component---src-pages-signup-index-tsx": () => import("./../../../src/pages/signup/index.tsx" /* webpackChunkName: "component---src-pages-signup-index-tsx" */),
  "component---src-pages-signup-provider-tsx": () => import("./../../../src/pages/signup/provider.tsx" /* webpackChunkName: "component---src-pages-signup-provider-tsx" */)
}

